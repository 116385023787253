import React from "react";
import { Link } from 'gatsby'
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import { PRESUPUESTO } from 'constants/routes';

import pricingStyle from "assets/jss/styles/pages/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing({ data }) {
  const classes = useStyles();
  const [active, setActive] = React.useState(0);
  const { price, price2, price3 } = data;
  return (
    <div className="cd-section">
      <div className={classes.pricing + " " + classes.sectionGray}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>
                El mismo modelo, diferentes acabados.
              </h2>
              <p className={classes.description}>
                Todos loa acabados cumplen con la normativa del CTE. Se han escogido 3 configuraciones de calidades de forma orientativa, pero es posible configurar a medida tu vivienda (fachada, suelos, cubierta,...). Contáctanos y te haremos un presupuesto a medida.
              </p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={7}
              md={7}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <NavPills
                alignCenter
                color="primary"
                active={active}
                setActive={setActive}
                tabs={[
                  {
                    tabButton: "Media",
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} md={6}>
                          <Card pricing raised>
                            <CardBody pricing>
                              {price ?
                                <h1 className={classes.cardTitle}>
                                  {price} €
                                </h1>
                              :
                                <h6 className={classes.ask}>
                                  CONSULTAR PRECIO
                                </h6>
                              }
                              <ul>
                                <li>
                                  Acabado en fachada con <b>esmalte sobre metal</b>
                                </li>
                                <li>
                                  Aislamientos térmicos interiores con <b>lanas minerales y espumas de poliuretano</b>
                                </li>
                                <li>
                                  Carpintería de <b>PVC o aluminio</b>, doble <b>vidrio climalit</b>
                                </li>
                                <li>
                                  Aire acondicionado tipo <b>SPLIT</b>
                                </li>
                                <li>
                                  <b>Proyecto, dirección de obra y montaje </b>(solo España)
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Link to={PRESUPUESTO}>
                                <Button color="primary" round>
                                  Pídelo ahora
                                </Button>
                              </Link>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Confort",
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} md={6}>
                          <Card pricing raised>
                            <CardBody pricing>
                              {price2 ?
                                <h1 className={classes.cardTitle}>
                                  {price2} €
                                </h1>
                              :
                                <h6 className={classes.ask}>
                                  CONSULTAR PRECIO
                                </h6>
                              }
                              <ul>
                                <li>
                                  Acabado en fachada con revestimiento tipo <b>SATE</b>
                                </li>
                                <li>
                                  Aislamientos térmicos interiores con lanas minerales y <b>láminas termorreflectivas</b>
                                </li>
                                <li>
                                  Carpintería de <b>PVC, aluminio o madera</b>, doble <b>vidrio climalit</b>
                                </li>
                                <li>
                                  Aire acondicionado por <b>conductos</b>
                                </li>
                                <li>
                                  Electrodimésticos, cocina y aseos de <b>gama media</b>
                                </li>
                                <li>
                                  <b>Proyecto, dirección de obra y montaje </b>(solo España)
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Link to={PRESUPUESTO}>
                                <Button color="primary" round>
                                  Pídelo ahora
                                </Button>
                              </Link>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Eco Pasive",
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} md={6}>
                          <Card pricing raised>
                            <CardBody pricing>
                              {price3 ?
                                <h1 className={classes.cardTitle}>
                                  {price3} €
                                </h1>
                              :
                                <h6 className={classes.ask}>
                                  CONSULTAR PRECIO
                                </h6>
                              }
                              <ul>
                                <li>
                                  Acabado en fachada con revestimiento tipo <b>SATE</b>
                                </li>
                                <li>
                                  Aislamientos térmicos interiores con lanas minerales y <b>láminas termorreflectivas</b>
                                </li>
                                <li>
                                  Carpintería de <b>PVC, aluminio o madera</b>, doble <b>vidrio climalit</b>
                                </li>
                                <li>
                                  Aire acondicionado con <b>aerotermia</b> 
                                </li>
                                <li>
                                  <b>Paredes radiantes</b>
                                </li>
                                <li>
                                  Instalación de <b>placas solares</b> en la cubierta
                                </li>
                                <li>
                                  Electrodimésticos, cocina y aseos de <b>gama alta</b>
                                </li>
                                <li>
                                  <b>Proyecto, dirección de obra y montaje </b>(solo España)
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Link to={PRESUPUESTO}>
                                <Button color="primary" round>
                                  Pídelo ahora
                                </Button>
                              </Link>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <p className={classes.description}>
                <b>No Incluye: Transporte (consultar, varía según la distancia) y cimentación (consultar, varía según tipo de terreno)</b>
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
